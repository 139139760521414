/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Common from './common';
import Footer from "./footer";

const Layout = ({ children, headerColor, whiteLogo = false }) => {
  return (
    <>
      <Common />
      <div className="site-layout home-layout">
        <header className="site-header">
          <Header headerColor={headerColor} whiteLogo={whiteLogo}/>
        </header>

        <div className="site-content">
          {children}
        </div>

        <footer className="site-footer">
          <Footer/>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
