import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import "./header.less"
import produceplanlogo from "../images/produceplanlogo.svg"
import produceplanlogowhite from "../images/produceplan-white.png"

import { appUrl } from "../helpers/helpers"

const Header = ({ siteTitle, whiteLogo, headerColor }) => {
  const menuItemsOurProduct = [
    <li><Link to="/features/quickbooks-integration">Finance & Accounting</Link></li>,
  ];

  const menuOurProduct = (
    <ul className="home-b-header-menu-dropdown">
      {menuItemsOurProduct}
    </ul>
  );

  const menuItemsFeatures = [
    <li><Link to="/features">Demand Planning</Link></li>,
    <li><Link to="/features">Supply Forecasting</Link></li>,
    <li><Link to="/features/quickbooks-integration">Orders & Invoicing</Link></li>,
    <li><Link to="/traceability">Warehousing & Freight</Link></li>,
  ];

  const menuFeatures = (
    <ul className="home-b-header-menu-dropdown">
      {menuItemsFeatures}
    </ul>
  );

  const menuItemsShippers = [
    <li><Link to="/for-growers-shippers">Growers/Shippers</Link></li>,
    <li><Link to="/for-growers-shippers/internet-of-things">Internet of Things</Link></li>,
  ];

  const menuShippers = (
    <ul className="home-b-header-menu-dropdown">
      {menuItemsShippers}
    </ul>
  );

  return (
    <div id="header-2" className="{{#if isHomeB}}home-page{{/if}} home-b" style={{
      ...headerColor && {
        backgroundColor: headerColor,
      }
    }}>
      <div className="header-banner">
        {/* link to Calendly: */}
        <a href="https://calendly.com/produceplanco/founder-meeting-demo" target="_blank" rel="noopener noreferrer">
          Schedule a demo with the Founder(s). We would love to meet you!
        </a>
      </div>
      <div id="header-2-content" className="container">
        <div id="sostena-logo">
          <Link to="/">
            <img
              src={whiteLogo ? produceplanlogowhite : produceplanlogo}
              alt="Produceplan"
            />
          </Link>
        </div>
  
        <div id="navigation">
          <div id="home-b-header-menu" className={whiteLogo && 'white-text'}>
            <div className="home-b-header-menu-item">
              <Link to="/why-produceplan">Why Produceplan</Link>
            </div>
            <div className="home-b-header-menu-item">
              <Link to="/features/quickbooks-integration">Our Product</Link>
              {/* <Dropdown overlay={menuOurProduct}>
                <Link to="/features/quickbooks-integration">Our Product</Link>
              </Dropdown> */}
            </div>
            <div className="home-b-header-menu-item">
              <Dropdown overlay={menuShippers}>
                <Link to="/for-growers-shippers">Growers/Shippers</Link>
              </Dropdown>
            </div>
            <div className="home-b-header-menu-item">
              <Link to="/traceability">Traceability</Link>
            </div>
            <div className="home-b-header-menu-item">
              <Link to="/about">About</Link>
            </div>
            <div className="home-b-header-menu-item login-button">
              <a
                className="btn orange btn-shadowless btn-rounded"
                href={`${appUrl}/login`}
              >
                Log in
              </a>
            </div>
            <div className="home-b-header-menu-item signup-button">
              <a
                className="btn btn-shadowless btn-rounded"
                href={`${appUrl}/signup`}
              >
                Sign up
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
